import { useState, useEffect } from "react";
import { Navbar, Container, Nav, Row } from "react-bootstrap";
import React from "react";
import { BrowserRouter as Router} from "react-router-dom";
import { HashLink as Link} from 'react-router-hash-link';

import zdj1 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/8Mwebp/501539522.jpg';
import zdj2 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/REWALWebp/313161274.jpg';

export const Banner = () => {
  return (
    <section className="banner">
    <Router id="banner">
      <Container>
        <Row className="box">
          <div className="img-szczecin">
            <img src={zdj1} alt="Item 1" />
          </div>
          <div className="img-szczecin">
            <p>
            Odkryj Szczecin, urokliwe miasto położone nad Odrą! Zatrzymaj się w naszych nowoczesnych, starannie zaprojektowanych apartamentach, znajdujących się w idealnej lokalizacji. </p> 
            <p className="contact"> Skontaktuj się z nami już teraz i znajdź swoje wymarzone miejsce w sercu Szczecina!</p>
            {/* Use HashLink to navigate within the same page */}
            <Link smooth to="#apartments-szczecin">
              <button className="banner-towns">
                <span>Apartamenty Szczecin</span>
              </button>
            </Link>
            </div>
        </Row>
        <Row className="box">
          <div className="img-szczecin">
          <p>
          Zaplanuj wyjątkowy pobyt w Rewalu, nadmorskim kurorcie, i wybierz nasze nowoczesne apartamenty. Znajdziesz tu starannie urządzone mieszkania w dogodnych lokalizacjach, zachęcające do korzystania z uroków tego malowniczego miejsca.</p> 
            <p className="contact"> Skontaktuj się z nami już teraz i odkryj swoje wymarzone miejsce nad morzem w Rewalu!</p>
            <p className="contact"> Czynne cały rok! </p>
            {/* Use HashLink to navigate within the same page */}
            <Link smooth to="#apartments-rewal">
              <button className="vvd">
                <span>Apartamenty nad morzem Rewal</span>
              </button>
            </Link>
          </div>
          <div className="img-szczecin">
            <img src={zdj2} alt="Item 1" />
          </div>
        </Row>
      </Container>
    </Router>
   </section>
  );
};





/* <section className="banner">
  <Container > 
    <Row className="box">
  <div className="img-szczecin">
      <img src={zdj1} alt="Item 1" /> 
  </div>
  <div className="img-szczecin">
    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
    <button className ="banner-towns" onClick={() => console.log("#apartments-rewal")} ><span>Apartamenty Szczecin</span></button>
  </div>
    </Row>
    <Row className="box">
  <div className="img-szczecin">
    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
  <button className ="vvd" onClick={() => console.log("apartments-rewal")} ><span>Apartamenty nad morzem Rewal</span></button>
  </div>
  <div className="img-szczecin">
  <img src={zdj2} alt="Item 1" /> 
  </div>
    </Row>
  </Container>
  </section>
  )
} */

