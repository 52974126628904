import { Container, Row, Col } from "react-bootstrap";
import React from "react";
import logo from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/logo.svg';

export const Footer = () => {
  return (
    <section className="footer" id="footer">
      <div className="footer">
        {/* Information on the left side */}
        <div className="info">
        <h1>Kontakt</h1>
            <p>+48 601 76 26 07</p>
            <p>biuro@klepaccy.pl</p>
            <p>Kontakt całodobowy 24/7</p>
          
            
      
          <h1>Dane</h1>
             <p>COMMTEL Mariusz Klepacki</p>
             <p>ul. Ustki 6/14, 70-831 Szczecin</p>
             <p>NIP: 955-125-48-47</p>
             <p>Rachunek bankowy: 40 1020 4795 0000 9902 0005 4239</p>
             
     
  
      
          <img src={logo} alt="Description" />
          <h3>Copyright 2023. All Rights Reserved</h3>
        </div>
        </div>
     </section>
    );
  };
  
    // <footer className="footer" id="footer">
    //   <Container>
    //       <Row className="info">
          
    //       <Col size={12} sm={6}>
    //       <h1>Kontakt</h1>
    //        <p>+48 601 76 26 07</p>
    //         <p>biuro@klepaccy.pl</p>
    //         <p>Kontakt całodobowy 24/7</p>
            
        
    //         <h1>Dane</h1>
    //         <p>COMMTEL Mariusz Klepacki</p>
    //         <p>ul. Ustki 6/14, 70-831 Szczecin</p>
    //         <p>NIP: 955-125-48-47</p>
    //         <p>Rachunek bankowy: 40 1020 4795 0000 9902 0005 4239</p>
    //         <h3>Copyright 2023. All Rights Reserved</h3>
    
    //       </Col>
    //       </Row>

    //         <Row className="img"> 
    //         <Col size={12} sm={6}>
    //         <img src={logo} alt="Logo" />    </Col></Row>
    //     </Container>
    // </footer>
