import React from "react"
import { Container,  Row} from "react-bootstrap"

export const About = () => {
    return (
    <section className="about" id="about">
        <Container> 
            <Row> 
       
            <h1>O NAS</h1>
            <p>Nasza firma specjalizuje się w krótkoterminowym wynajmie mieszkań w urokliwym Rewalu oraz dynamicznym Szczecinie od 2017 roku. Z pasją i zaangażowaniem zapewniamy naszym gościom komfortowe i nowoczesne miejsca do wypoczynku. Nasze  apartamenty,  bogate w liczne udogodnienia  stanowią doskonałe rozwiązanie dla podróżujących, którzy cenią wygodę i wyjątkowy klimat naszych lokalizacji. </p>

            </Row>
        </Container> 
    </section>
    )
}